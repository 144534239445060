import { BaseEntityModel } from '@common-src/model/base-model';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { ChannelTypeEnum, ChannelTypeList } from '@common-src/entity-model/channel-entity';
import ChannelService from '@common-src/service/channel';
import MessageLoggerService from '@/service/message-logger.service';
import { Moment } from 'moment';
import { getEndTimeByIntervalType } from '@common-src/utils/time_uitil';
import { PeriodTypeEnum } from '@common-src/model/enum';

export class MessageLoggerEntityModel extends BaseEntityModel {
    static baseUrl = `${MESSAGE_BASE_REQUEST_PATH}/log`;

    batchId:string = undefined;
    channelName: string = undefined;
    templateName: string = undefined;
    channelType: ChannelTypeEnum = undefined;
    messageReceiver: string = undefined;
    messageReceiverType: string = undefined;
    messageReceiverName: string = undefined;
    templateId: string = undefined;
    sendTime: string = undefined;
    appName: string = undefined;
    remark: string = undefined;
    messageContent: string = undefined;
    modelStatus:number = null;
    status:number = null;

    static getTableColumns() {
        return [
            {
                title: '批次ID',
                dataIndex: 'batchId'
            },
            {
                title: '来源',
                dataIndex: 'appName'
            },
            {
                title: '消息渠道类型',
                dataIndex: 'channelType',
                customRender: (text) => {
                    const { name } = _.find(ChannelTypeList, item => item.value === text);
                    return name;
                }
            }, {
                title: '消息渠道名称',
                dataIndex: 'channelName'
            }, {
                title: '模板名称',
                dataIndex: 'templateName'
            }, {
                title: '接收人',
                dataIndex: 'messageReceiverName',
                scopedSlots: { customRender: 'receiverName' }
            }, {
                title: '消息内容',
                dataIndex: 'messageContent',
                scopedSlots: { customRender: 'content' }
            }, {
                title: '发送结果',
                dataIndex: 'status',
                scopedSlots: { customRender: 'status' }
            }, {
                title: '发送时间',
                dataIndex: 'sendTime'
            }
        ];
    }
    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class MessageLoggerQueryModel extends QueryPageModel {
    @QueryControl({
        label: '渠道类型',
        type: QueryControlType.SELECT,
        optionsPromise: ChannelService.channelTypeList,
        hasAllOption: true,
        span: 6
    })
    channelType: ChannelTypeEnum = undefined;

    @QueryControl({
        label: '来源名称',
        type: QueryControlType.TEXT,
        span: 6
    })
    appName: string = undefined;

    @QueryControl({
        label: '模板名称',
        type: QueryControlType.SELECT,
        optionsPromise: MessageLoggerService.getTemplateList,
        hasAllOption: true,
        span: 6
    })
    templateId: string = undefined;

    @QueryControl({
        label: '批次ID',
        type: QueryControlType.TEXT,
        span: 6
    })
    batchId: string = undefined;

    @QueryControl({
        label: '发送时间',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 6
    })
    dateRange: Array<Moment> = [];

    toService() {
        const data = super.getParams();
        if (this.dateRange.length > 0) {
            // data.params.start = +this.dateRange[0];
            data.params.start = this.dateRange[0].startOf('day').valueOf();
            data.params.end = Date.parse(getEndTimeByIntervalType(this.dateRange[1], PeriodTypeEnum.CUSTOM) + ' 23:59:59');
        }
        delete data.params.dateRange;
        return data;
    }
}
