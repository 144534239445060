









































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { MessageLoggerEntityModel, MessageLoggerQueryModel } from '@/entity-model/message-logger-entity';
import MessageLoggerService from '@/service/message-logger.service';

@Component
export default class MessageTemplateListComponent extends TableDialogFormComponent<MessageLoggerEntityModel, MessageLoggerQueryModel> {
    MessageLoggerEntityModel = MessageLoggerEntityModel;
    created() {
        this.initTable({
            service: new MessageLoggerService(),
            queryModel: new MessageLoggerQueryModel(),
            tableColumns: MessageLoggerEntityModel.getTableColumns()
        });
        this.getList();
    }
}

