import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import { MessageLoggerEntityModel, MessageLoggerQueryModel } from '@/entity-model/message-logger-entity';
import { TemplateEntityModel } from '@common-src/entity-model/template-entity';
import http from '@common-src/service3/http/axios';

export default class MessageLoggerService extends BaseCRUDQService<MessageLoggerEntityModel, MessageLoggerQueryModel> {
    constructor(baseUrl = MessageLoggerEntityModel.baseUrl) {
        super(baseUrl, MessageLoggerEntityModel);
    }
    static async getTemplateList():Promise<any> {
        const url = `${TemplateEntityModel.baseUrl}/list`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params: {
                    limit: 1000,
                    page: 1,
                    params: {}
                }
            }
        );
        return _.map(res.items || res, item => ({
            name: item.templateName,
            value: item.id
        }));
    }
}
